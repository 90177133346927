var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingAnnouncements)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),(!_vm.loadingAnnouncements && _vm.announcements.length === 0)?_c('div',{staticClass:"empty"},[_vm._v(" "+_vm._s(_vm.$t("announcements.noRoasOrAnnouncements"))+" ")]):_vm._e(),(!_vm.initializeParent && !_vm.initializeRepo && _vm.announcements.length)?_c('el-row',{staticClass:"table-header",attrs:{"align":"middle"}},[_c('el-col',{attrs:{"xs":24,"sm":8}},[_c('el-input',{attrs:{"size":"mini","prefix-icon":"el-icon-search","clearable":"","placeholder":_vm.$t('announcements.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('el-col',{attrs:{"xs":24,"sm":16}},[_c('div',{staticClass:"show-bgp"},[_c('download-csv',{staticClass:"download-csv",attrs:{"fields":['asn', 'prefix', 'max_length', 'state'],"data":_vm.filteredAnnouncements,"name":_vm.handle + '.csv'}},[_c('el-button',{staticClass:"mt-1",attrs:{"type":"text"}},[_c('font-awesome-icon',{attrs:{"icon":"file-csv"}}),_vm._v(" "+_vm._s(_vm.$t("announcements.download"))+" ")],1)],1)],1)])],1):_vm._e(),(_vm.loadingTable)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),(!_vm.loadingAnnouncements && !_vm.loadingTable && _vm.announcements.length)?_c('el-table',{ref:"roaTable",staticStyle:{"width":"100%"},attrs:{"size":"small","data":_vm.filteredAnnouncements,"default-sort":_vm.currentSort,"row-class-name":_vm.getRowClass,"empty-text":_vm.$t('common.nodata')},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.authorizes || scope.row.disallows)?_c('el-row',{attrs:{"align":"middle","gutter":20}},[_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('h4',{staticClass:"popover-title"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                  path: 'announcements.authorizes',
                  args: { number: scope.row.authorizes ? scope.row.authorizes.length : '0' },
                }),expression:"{\n                  path: 'announcements.authorizes',\n                  args: { number: scope.row.authorizes ? scope.row.authorizes.length : '0' },\n                }"}]})]),_c('el-table',{staticStyle:{"width":"100%","margin-bottom":"2rem"},attrs:{"size":"small","data":scope.row.authorizes,"empty-text":_vm.$t('common.nodata')}},[_c('el-table-column',{attrs:{"prop":"asn","label":_vm.$t('announcements.asn')}}),_c('el-table-column',{attrs:{"prop":"prefix","label":_vm.$t('announcements.prefix')}})],1)],1),_c('el-col',{attrs:{"xs":24,"sm":12}},[_c('h4',{staticClass:"popover-title"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                  path: 'announcements.disallows',
                  args: { number: scope.row.disallows ? scope.row.disallows.length : '0' },
                }),expression:"{\n                  path: 'announcements.disallows',\n                  args: { number: scope.row.disallows ? scope.row.disallows.length : '0' },\n                }"}]})]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"size":"small","data":scope.row.disallows,"cell-class-name":"el-cell--mini","empty-text":_vm.$t('common.nodata')}},[_c('el-table-column',{attrs:{"prop":"asn","label":_vm.$t('announcements.asn')}}),_c('el-table-column',{attrs:{"prop":"prefix","label":_vm.$t('announcements.prefix')}}),_c('el-table-column',{attrs:{"width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"icon":"el-icon-plus","type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.triggerAddROA(scope.row)}}})]}}],null,true)})],1)],1)],1):_vm._e()]}}],null,false,221437145)}),_c('el-table-column',{attrs:{"prop":"asn","label":_vm.$t('announcements.asn'),"sortable":"","width":"200"}}),_c('el-table-column',{attrs:{"label":_vm.$t('announcements.prefix'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.prefix)+_vm._s(scope.row.max_length ? "-" + scope.row.max_length : "")+" ")]}}],null,false,2010804456)}),_c('el-table-column',{attrs:{"prop":"state","label":_vm.$t('announcements.stateLabel'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('stateVisualizer',{attrs:{"scope":scope}})]}}],null,false,3705830254)}),_c('el-table-column',{attrs:{"prop":"state","width":"130","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.state === 'announcement_not_found' ||
            scope.row.state === 'announcement_invalid_asn' ||
            scope.row.state === 'announcement_invalid_length'
          )?_c('el-button',{attrs:{"icon":"el-icon-plus","type":"primary","size":"mini","round":""},on:{"click":function($event){return _vm.triggerAddROA(scope.row)}}}):_vm._e(),(scope.row.state !== 'announcement_not_found' && scope.row.max_length)?_c('el-button',{staticStyle:{"margin-left":"4px"},attrs:{"size":"mini","icon":"el-icon-delete","type":"primary","round":""},on:{"click":function($event){return _vm.deleteROA(scope.row)}}}):_vm._e()]}}],null,false,3511229837)})],1):_vm._e(),(
      !_vm.loadingAnnouncements &&
      !_vm.loadingTable &&
      _vm.announcements.length > 0 &&
      _vm.filteredAnnouncements.length > 0
    )?_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"page-sizes":[10, 25, 100, 500],"page-size":_vm.pageSize,"layout":"sizes, prev, pager, next","total":_vm.totalRecords},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"current-change":_vm.preFilterAnnouncements}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }